


import React from "react"
import SEO from "../components/seo"
// import Image from "../components/image"


const DefinitPage = () => (
  <div  id="app_center" class="container_center">
    <SEO title="Ce qui nous définit" description="Une société de service informatique mais pas uniquement. Ces différents points etablissent l'ADN de notre société et ce pourquoi vous aimerez travailler avec nous."/>

    <h1>Ce qui nous définit</h1>

    <div class="container_line">Notre <span class='bold'>flexibilité</span> et <span class='bold'>efficacité</span>, car un projet evolu et vos besoins avec.</div>

    <div class="container_line">Notre <span class='bold'>réactivité</span> et <span class='bold'>disponibilité</span>, nous vous proposons des solutions dans les meilleurs délais.</div>

    <div class="container_line">Nos <span class='bold'>solutions innovantes</span>, nous explorons et maitrisons les nouvelles technologies pour vous les restituer le plus simplement possible.</div>

    <div class="container_line">Notre <span class='bold'>service client</span>, véritable <span class='bold'>cœur de métier</span>, nous prenons un soin tout particulier à la prise en compte de vos demandes car votre satisfaction est primordiale.</div>
  </div>
)

export default DefinitPage


